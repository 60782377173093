import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import marked from "marked";

class Covid extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const [banner] = get(this, "props.data.allContentfulBanner.edges");
    const [pageData] = get(this, "props.data.allContentfulCovid.edges");

    return (
      <Layout location={this.props.location}>
        <div style={{ background: "#fff" }}>
          <Helmet
            title={`Covid-19 - ${siteTitle}`}
            meta={[
              {
                name: "description",
                content: "COVID-19 Information at Aoga Amata Newtown",
              },
              {
                name: "keywords",
                content: "covid-19, daycare, wellington, newtown",
              },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Hero data={banner.node} />
          <div className="wrapper">
            <div
              className="section-content"
              dangerouslySetInnerHTML={{
                __html: marked(pageData.node.content.content),
              }}
            ></div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Covid;

export const pageQuery = graphql`
  query CovidQuery {
    allContentfulBanner(
      filter: { contentful_id: { eq: "57xZ1h0eCyVkRLMb5a6n0U" } }
    ) {
      edges {
        node {
          title
          heroImage: image {
            fluid(
              maxHeight: 500
              resizingBehavior: PAD
              background: "rgb:FFFFFF"
            ) {
              src
              srcSet
              base64
            }
          }
        }
      }
    }
    allContentfulCovid {
      edges {
        node {
          title
          content {
            content
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
